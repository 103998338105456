@font-face {
  font-family: "AvenirNextLTW02-Regular"; 
  src: url("//db.onlinewebfonts.com/t/be9a43357e0fe07642191e4b32995385.eot"); 
  src: url("//db.onlinewebfonts.com/t/be9a43357e0fe07642191e4b32995385.eot?#iefix") format("embedded-opentype"), 
  url("//db.onlinewebfonts.com/t/be9a43357e0fe07642191e4b32995385.woff2") format("woff2"), 
  url("//db.onlinewebfonts.com/t/be9a43357e0fe07642191e4b32995385.woff") format("woff"), 
  url("//db.onlinewebfonts.com/t/be9a43357e0fe07642191e4b32995385.ttf") format("truetype"), 
  url("//db.onlinewebfonts.com/t/be9a43357e0fe07642191e4b32995385.svg#AvenirNextLTW02-Regular") format("svg"); 
}

body{
  font-family: "AvenirNextLTW02-Regular", Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  color: #33475b;
}

a, a:active, a:visited a:hover{
  color: #007a8c;
  font-weight: 600;
  text-decoration: none;
}

a:hover{
  color: #007a8c;
  text-decoration: underline;
}

.header-inner a:hover{
  text-decoration: none;
}

h2{
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 14px;
}

h5 {
  font-size: 15px;
  font-family: Avenir Next W02,Helvetica,Arial,sans-serif;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 16px;
  margin-top: 0;
  display: block;
  line-height: normal;
  text-transform: none;
}

table td{
  padding-right: 10px;
}

.loader-fullpage.isLoading .loader-container {
  opacity: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(0, 0);
  transition: none;
}

.loader-fullpage .loader-container {
  opacity: 0;
}

.loader-fullpage {
  height: 0;
  width: 100%;
  position: fixed;
  z-index: 10000000;
  left: 0;
  top: 0;
  overflow-x: hidden;
  transition: 0.5s;
  text-align: center;
  color: #007a8c;
  transition: none;
}

.loader-fullpage.isLoading {
  height: 100%;
}

.loader-fullpage.isLoading .loader-container {
  opacity: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -20px;
  transform: translate(0, 0);
  transition: none;
}

.loader-fullpage .loader-container {
  opacity: 0;
}

.loader-fullpage .spinner-border {
  width: 40px;
  height: 40px;
  border-width: 2px;
}

.dialog {
  position: absolute;
  top: 10%;  
  min-width: 200px;
  min-height: 60px;
  line-height: 60px;
  padding: 1px 20px;
  background-color: #e5f5f8;
  border: 1px solid #7fd1de;
}

.dialog.hide{
  animation: fadeOut ease 3s forwards;
  animation-delay: 4s;
  opacity: 1;
}

.dialog.hidden{
  opacity: 0;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.navbar {
  width: 100%;
  height: 54px;
  background-color: rgb(46, 63, 80);
  font-family: "Avenir Next W02", "Avenir Next", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: 300;
  white-space: nowrap;
  position: relative;
  transition: none 0s ease 0s;
  padding-top: 0; 
  padding-bottom: 0;
  margin-bottom: 30px;
}

.navbar ul{
  list-style-type: none;
  margin-top: unset;
  margin-bottom: unset;
}

.navbar li{
  line-height: 54px;
  padding-left: 7px;
  padding-right: 7px;
  cursor: pointer;
  color: #fff;
  font-weight: 400;
  text-decoration: none;
}

.navbar li:hover {
  background-color: #253342;
}

.logo{
  height: 30px;
  width: 30px;
  margin-left:-3px;
}

.btn, .btn:active, .btn:visited {
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  font-family: "AvenirNextLTW02-Regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding: 8px 16px;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all .15s ease-out;
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  background-color: #ff7a59;
  border-color: #ff7a59;
  color: #fff;
  outline:none;
  text-decoration: none !important;
}

.btn:hover {
  background-color: #ff8f73;
  border-color: #ff8f73;
  color: #fff;
  text-decoration: none;
  outline:none;
}

.btn:active,
.btn:focus,
.btn:active {
  background-image: none;
  outline: 0;
  box-shadow: none;
}

.btn:disabled {
  background-color: #eaf0f6!important;
  border-color: #eaf0f6!important;
  color: #b0c1d4!important;
  cursor: not-allowed;
  pointer-events: initial;
}

.btn-check:focus+.btn-primary, .btn-primary:focus {
  background-color: #ff8f73;
  border-color: #ff8f73;
  color: #fff;
  box-shadow: none !important;
}

.box{
  box-shadow: 5px 10px 8px #888888;
  padding-bottom: 10px;
}

.show{
  opacity: 1;
  transition: 0.5s ease;
  height:100%;
}

.hide{
  opacity: 0;
  transition: 0.5s ease;
}

.communicator-header {
  background-image: none!important;
  background-color: #516f90;
  color: #fff;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  font-weight: 600;
  height: 50px;
  min-width: 200px;
  margin-bottom: 15px;
}

.communicator-header .header-inner {
  line-height: 50px;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.content-inner:last-child {
  padding-top: 15px;
  border-top: 1px solid #dfe3eb;
}

.content-inner {
  line-height: 22px;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  
}

.close {
  font-weight: 400;
  font-size: 34px;
  width: 15px;
  margin-top:-1px;
  text-decoration: none;
  color: #fff;
}

textarea{
  outline: none;
  border: none;
  width: 100%;
  resize: none;
  overflow: hidden;
  margin-bottom:-10px;
  line-height: 22px;
}

.form-control {
  width: 100%;
  font-size: 15px;
  line-height: 22px;
  text-align: left;
  vertical-align: middle;
  color: rgb(51, 71, 91);
  background-color: rgb(245, 248, 250);
  border: 1px solid rgb(203, 214, 226);
  border-radius: 3px;
  padding: 9px 10px;
  transition: all 150ms ease-out 0s;
  height: auto;
  min-height: 40px;
  resize: none;
  display: inline-block;
  font-family: "Avenir Next W02", Helvetica, Arial, sans-serif !important;
  font-weight: 400 !important;
}

.form-control:focus { 
  border-radius: 3px;
  border: 1px solid rgba(0, 208, 228, 0.5);
  background-color: rgb(245, 248, 250);
  outline: 0px;
  box-shadow: none;
}

.editor{
  padding-bottom:15px;
}

.error{
  border-color: #ff7a59;
}

@media (min-width: 768px) { 
  .navbar li{
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 992px) { 
  .navbar ul{
    margin-top:0;
  }

  .navbar li{
    line-height: 54px;
  }

  .logo{
    margin-top:0;
  }
}