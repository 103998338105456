.tox-editor-container {
    margin-top: -10px !important;
}

.tox .tox-editor-header {
    z-index: 0 !important;
}

.tox .tox-dialog {
    border-radius: 3px !important;
    box-shadow: 0 1px 24px 0 rgb(0 0 0 / 8%) !important;
    background-color: #fff !important;
    border: 1px solid #cbd6e2 !important;
} 

.tox .tox-dialog__title {
    font-size: 16px !important;
    font-family: 'Avenir Next W02',Helvetica,Arial,sans-serif !important;
    font-weight: 600 !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    margin-top: 0 !important;
    display: block !important;
    line-height: normal !important;
    text-transform: none !important;
}

.tox .tox-edit-area__iframe {
    background-color: rgb(245, 248, 250) !important;
    padding-left: 10px;
}

.tox .tox-listboxfield .tox-listbox--select, .tox .tox-textarea, .tox .tox-textfield, .tox .tox-toolbar-textfield {
    padding: 9px 10px !important;
    border-radius: 3px !important;
    border: 1px solid #cbd6e2 !important;
    font-size: 16px !important;
    transition: all .15s ease-out !important;
    background-color: #f5f8fa !important;
    color: #33475b !important;
    height: 40px !important;
    line-height: 22px !important;
    text-align: left !important;
    width: 100% !important;
}

.tox .tox-listboxfield .tox-listbox--select:focus, .tox .tox-textarea:focus, .tox .tox-textfield:focus, .tox .tox-toolbar-textfield:focus {
    border-color: rgba(0,208,228,.5) !important;
    box-shadow: 0 0 4px 1px rgb(0 208 228 / 30%), 0 0 0 1px #00d0e4 !important;
    outline: 0 !important;
}

.tox .tox-button, .tox .tox-button:hover, .tox .tox-button:hover:not(:disabled) {
    background-color: #eaf0f6 !important;
    border-color: #cbd6e2 !important;
    color: #506e91 !important;
    border-radius: 3px !important;
    border-style: solid !important;
    border-width: 1px !important;
    -webkit-font-smoothing: auto !important;
    -moz-osx-font-smoothing: auto !important;
    font-family: 'Avenir Next W0',Helvetica,Arial,sans-serif !important;
    font-weight: 400 !important;
    text-align: center !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    transition: all .15s ease-out !important;
    display: inline-block !important;
    max-width: 100% !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    vertical-align: middle !important;
    white-space: nowrap !important;
}

.tox .tox-label, .tox .tox-toolbar-label {
    padding: 8px 12px 4px 0 !important;
    font-family: 'Avenir Next W02',Helvetica,Arial,sans-serif !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #33475b !important;
    line-height: 24px !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    display: inline-block !important;
    vertical-align: middle !important;
}

.tox .tox-button--naked, .tox .tox-button--naked:hover {
    color: #506e91 !important;
    border: none !important;
    background-color: unset !important;
}

.tox .tox-button--naked.tox-button--icon:hover:not(:disabled) {
    color: #506e91 !important;
    border: none !important;
    background-color: unset !important;
}

.tox .tox-dialog__footer {
    background-color: #fff !important;
    border-top: none !important;
    padding-right: 16px !important;
    padding-bottom: 16px !important;
}

.tox .tox-statusbar {
    display:none !important;
}