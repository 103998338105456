@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);

body {
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
}

.header {
    flex-wrap: nowrap;
    padding: 14px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    transition: .5s;
}

.header.darkHeader {
    background: linear-gradient(115.26deg,#4dd6b0 -.06%,#4d5be5 106.1%);
    z-index: 9999;
    transition: .5s;
}

.header .btn-primary{
    width: 120px;
    font-size: 15Px;
}


.header .btn-primary{
    background: #2d3691 !important;
    color: #4dd6b0;
}

.header .btn-primary:hover {
    background: #4dd6b0 !important;
    color: #2d3691;
}

.text-right {
    text-align: right!important;
}

.component.header .logo {
    height: 100px;
    width: 100px;
}

.content{
    padding-top: 190px;
    padding-bottom: 50px;
    padding-left:10px;
    padding-right:10px;
}

.content h1 {
    font-family: Poppins;
    font-weight: 600;
    font-size: 48px;
    line-height: 48px;
    text-align: center;
    color: #2d3691;
    margin-bottom: 50px;
}

.content h2 {
    font-family: Poppins;
    font-weight: 500;
    font-size: 48px;
    line-height: 48px;
    text-align: center;
    color: #2d3691;
    margin-top: 30px;
    margin-bottom: 50px;
}

.content h3 {
    font-family: Poppins;
    font-weight: 400;
    margin-top: 30px;
}

.content p {
    margin: 0;
    padding: 0 0 40px 0;
    font-family: Poppins,sans-serif;
    font-size: 16px;
    color: #494d5a;
    line-height: 28px;
    font-weight: 400;
}

.content ol li{
    font-family: Poppins,sans-serif;
    padding-bottom: 15px;
    padding-left: 15px;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
}

.btn-primary {
    background: #4dd6b0 !important;
    box-shadow: 0 4px 0 rgb(45 54 145 / 10%) !important;
    border-radius: 24px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 48px !important;
    padding-left:30px;
    padding-right:30px;
    color: #2d3691;
    letter-spacing: .04em;
    border: none;
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
    text-decoration: none;
}

.btn-primary:hover{
    background: #2d3691 !important;
    color: #4dd6b0;
    text-decoration: none;
}


.footer {
    background: #2d3691;
    color:#fff;
    font-size: 16px;
    text-align: center;
}

.footer h2 {
    font-family: Poppins;
    font-weight: 500;
    margin-bottom: 5px;
}

.footer a {
    color: #fff;
    font-weight: 400;
}

.footer a:hover {
    color: #4dd6b0;
    text-decoration: none;
}

.footer .foot-bottom{
    padding-top: 30px;
}

@media (min-width: 576px) { 
    .header .btn-primary{
        width: 150px;
        font-size: 15Px;
    }

    .footer {
        text-align: left;
    }
}

@media (min-width: 992px) { 
    .component.header .logo {
        height: 100px;
        width: 375px;
    } 
}